import { Component, OnInit,AfterViewInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService, AppDetails, UserAppDetails,DeleteUserDetails } from "../authentication.service";
import { environment } from "src/environments/environment.prod";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import * as $ from "jquery";
import {  MatTableDataSource, MatPaginator, MatSort } from "@angular/material";
import { NgForm } from '@angular/forms';
declare var $: any;
export interface UserData {
  name: string;
  email: string;
  jobtitle: string;
  credits_assigned: number;
  credits_balance: number;
}
export interface ApplicationData {
  menu_name: string,
  application_name: string,
  application_url: string,
  industry: string,
  function: string
}

export interface RestartApplicationData {
  menu_name: string,
  application_name: string,
  application_url: string,
  username: string,
  password: string
}


@Component({
  selector: "app-landing",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit, AfterViewInit {
  constructor(private auth: AuthenticationService, private router: Router) {
    // this.applicationDetaisl.userAccess = this.dataSourcetable.data.map(user => user);

  }


  reportData = [
    { Application: 'Invoice Toolbox', 'Frontend Status': 'Login Successful', 'Backend Status': 'Active' },
    { Application: 'Email Personalisation', 'Frontend Status': 'Login Successful', 'Backend Status': 'Inactive' }
    // Add other report data here
  ];

  isPopupOpen = true;

  openReport() {
    this.isPopupOpen = true;
  }

  condition: any;
  accesslevel2: string;
  header: string;
  title = "Neuron";
  email = localStorage.getItem('email');
  showcomputermenu: boolean = false;
  shownlpmenu: boolean = false;
  showsoundmenu: boolean = false;
  showautomlmenu: boolean = false;
  showtelescopemenu: boolean = false;
  showrecommendationmenu: boolean = false;
  showgenerativemenu: boolean = false;
  showtimeseriesmenu: boolean = false;
  showanomalydetection: boolean = false;

  applicationidarray: any = [];
  activemenu: string = '';
  // applicationDetails: any[] = []; // This should be populated with your application data
  selectedOptions: { [key: number]: boolean } = {}; // To bind checkbox state
  appaccessNames: number[] = []; // Array to hold selected IDs

  computerdata: any = [];
  coviddata: any = [];
  nlpdata: any = [];
  sounddata: any = [];
  automl: any = [];
  telescope: any = [];
  recommendation: any = [];
  genrativeai: any = [];
  timeseries: any = [];
  anomalydetection: any = [];

  detailReport: any = []
  displayedColumns: string[] = ['batchName', 'userName', 'testRunAt', 'File_attached', 'test_completed_at', 'reportFile'];
  displayedColumnsRestartApp: string[] = ['menu_name', 'application_name', 'application_url', 'username', 'password','action'];

  dataSource: MatTableDataSource<any>;
  dataSource2 = new MatTableDataSource<any>();



  totalLength = 0;
  totalLength2 = 0;
  automationUserName = '';
  menuNames: any = [];
  applicationNames: any = [];
  userNames: any = [];
  
  filteredApplications = [];
  originalApplications = [];
  restartappdata: any = [];


  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator2: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('f', { static: false }) form: NgForm; // Updated to include opts
  @ViewChild('newTablePaginator', { static: false }) newTablePaginator: MatPaginator;
 @ViewChild('newTableSort', { static: false }) newTableSort: MatSort;
 @ViewChild('applicationTablePaginator', { static: false }) applicationTablePaginator: MatPaginator;
 @ViewChild('datasource2TablePaginator', { static: false}) datasource2TablePaginator: MatPaginator


  searchdata = [];
  searchdata2 = [];
  searchdata3 = [];
  searchdata4 = [];
  searchdata5 = [];
  searchdata6 = [];
  searchdata7 = [];
  searchdata8 = [];
  searchdata9 = [];
  searchdata10 = [];
  automationAccess: boolean = false;
  applicationDetails = [];

  searchText = "";
  applicationDetaisl: AppDetails = {
    id: 0,
    menuName: "",
    appName: "",
    appUrl: "",
    appIndustry: "",
    appFunction: "",
    appStatus: 1,
    appDescription: "",
    appDocs: "",
    backendApiUrl: "",
    loginMethod: "",
    applicationUsername: "",
    applicationPassword: "",
    buttontext: "",
    buttonstate: 0,
    color: "",
    appImage: "",
    userAccess: []
  };

appaccessStatus: { applicationId: number; appaccessStatus: boolean }[] = []; // To track application access



  userDetails: UserAppDetails = {
    id: 0,
    userName: "",
    userEmail: "",
    userJobTitle: "",
    userCompanyName:"",
    userPassword:"",
    userCreditAssigned:null,
    userCreditBalance: "",
    menuName:"",
    appaccessNames:[],
    appaccessdata: []
  };

  DeleteUserDetails: DeleteUserDetails = {
    user_id : 0
  }


  showUploadfileButton :boolean = false;
  showUploadimageButton : boolean = false;
  appuserid:any;
  originalData: ApplicationData[] = [];
  originalapprestartdata : any = [];

  // documnetsdata: FileDetails ={
  //   file:""
  // }

  displayedtableColumns: string[] = ['name', 'email', 'jobtitle', 'credits_assigned', 'credits_balance', 'action'];
  displayeApplicationColumn: string[] = ['menu_name', 'application_name', 'application_url', 'industry', 'function', 'action'];

  isModalOpen: boolean = false;
  selectedFile: File | null = null;
  isFileTypeInvalid: boolean = false;

  selectedImageFile: File | null = null;
  isImageFileTypeInvalid: boolean = false;


  isEditing: boolean = false;
  isLoading: boolean = false;
  isLoadingGenerateReport: boolean = false;
  isEditingUserModal: boolean = false;

  dataSourcetable = new MatTableDataSource<UserData>();
  dataSourceapplicationstable = new MatTableDataSource<ApplicationData>();
  dropdownSettings = {};

  isReportPopupVisible: boolean = false;
  selectedBatchData: any[] = [];

  dataSourceapplicationstable2 = new MatTableDataSource<RestartApplicationData>();
  selectedTab: string = 'automationreport';
  dataFiletermenuname: any = [];
  selectedMenuNames: any[] = [];  // Array to store selected menu names
  originalApplicationNames: any[] = []; // Store the original list of applications here
  dataFileterApplicationname: any[] = []; // This will hold the selected application names
  filteredApplicationNames: any[] = []; // This will hold the filtered application names
  menuNamesofrestarttab: any = [];
  

  ngOnInit(): void {
    if (this.email == environment.automationUserName) {
      this.automationAccess = true;
    }
    else {
      this.automationAccess = false;
    }

    this.activemenu = '';
    clearAllBodyScrollLocks();
    // sessionStorage.removeItem('header');

    this.getuserdata();
    this.getDynamicCardCreation();
    this.getAllUsersDetails();
    this.getAllApplicationDetails();
    this.getAllMenuNames();
    this.getAllApplicationRestartData();
    // this.getPm2StatusCheck();

    this.accesslevel2 = sessionStorage.getItem("accesslevel");
    const isTrueSet = this.accesslevel2 === "true";
    this.condition = isTrueSet;

    // this.header = 'false';
    // this.applicationDetaisl.userAccess = this.dataSourcetable.data.users.id

    

    $(document).ready(function () {
      const targetElement = $("#onscroll");

      enableBodyScroll(targetElement);
      this.filteredApplications = this.applicationDetails;
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Search Users',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  dropdownSettings2 = {
    singleSelection: false,
    idField: 'menu_name',      
    textField: 'menu_name',     
    itemsShowLimit: 5,
    allowSearchFilter: true
  };

  ngAfterViewInit() {
    setTimeout(() => {
      this.dataSourcetable.paginator = this.newTablePaginator;
      this.dataSourcetable.sort = this.newTableSort;
      this.dataSourceapplicationstable.paginator = this.applicationTablePaginator;
      this.dataSource2.paginator = this.datasource2TablePaginator;
      this.applicationTablePaginator.pageSizeOptions = [5, 10, 20, 50];
      console.log('Paginators and Sort assigned successfully');
    }, 0);
  }
  

  getAllApplicationRestartData() {
    this.auth.showAllRestartApplication()
    .subscribe(
      (response: RestartApplicationData[]) => {
        this.restartappdata = response;
      },
      (err) => {
        console.log(err);  // Handle any errors
      }
      );
  }


  getPm2StatusCheck() {
    this.auth.pm2StatusCheck()
    .subscribe(
      (response) => {
        console.log(response)
      },
      (err) => {
        console.log(err);  // Handle any errors
      }
      );
  }


  selectTab(tabName: string) {
    this.selectedTab = tabName;
  }


  searchValue(value: any): void {
    let searchId: string = this.searchText.toLowerCase();
    // debugger

    this.searchdata = this.computerdata.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata2 = this.nlpdata.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata3 = this.sounddata.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata4 = this.coviddata.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata5 = this.automl.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata6 = this.telescope.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata7 = this.recommendation.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata8 = this.genrativeai.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata9 = this.timeseries.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });
    this.searchdata10 = this.anomalydetection.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });
  }

  getrepodata() {
    this.searchdata = this.computerdata;
    this.searchdata2 = this.nlpdata;
    this.searchdata3 = this.sounddata;
    this.searchdata4 = this.coviddata;
    this.searchdata5 = this.automl;
    this.searchdata6 = this.telescope;
    this.searchdata7 = this.recommendation;
    this.searchdata8 = this.genrativeai;
    this.searchdata9 = this.timeseries;
    this.searchdata10 = this.anomalydetection;
  }

  openlink(link: string, id: number) {
    // console.log(link)
    // console.log(id)
    if (id) {
      const token = localStorage.getItem('token');
      if (token) {
        const redirectUrl = `${link}?token=${token}`;
        window.open(redirectUrl, '_blank');
      } else {
        // Handle the case when the token is not available
        console.error('Token not found.');
      }
    } else {
      if (link && link.length > 0) {
        window.open(link, '_blank');
      }
    }
  }


  logout() {
    // Clear the local storage
    localStorage.clear();
    // Redirect to the login page
    this.router.navigateByUrl('/login');

  }

  getuserdata() {
    this.activemenu = '';
    this.auth.userdata(this.email).subscribe(
      (response) => {
        const menuarray = response.menu_name.split(',');

        this.applicationidarray = response.Access_id.split(',');
        if (menuarray.includes('computerdata')) {
          this.showcomputermenu = true;
          if (this.activemenu == '') {
            this.activemenu = 'computerdata';
          }
        }
        if (menuarray.includes('nlpdata')) {
          this.shownlpmenu = true;
          if (this.activemenu == '') {
            this.activemenu = 'nlpdata';
          }
        }
        if (menuarray.includes('sounddata')) {
          this.showsoundmenu = true;
          if (this.activemenu == '') {
            this.activemenu = 'sounddata';
          }
        }
        if (menuarray.includes('automl')) {
          this.showautomlmenu = true;
          if (this.activemenu == '') {
            this.activemenu = 'automl';
          }
        }
        if (menuarray.includes('telescope')) {
          this.showtelescopemenu = true;
          if (this.activemenu == '') {
            this.activemenu = 'telescope';
          }
        }
        if (menuarray.includes('recommendation')) {
          this.showrecommendationmenu = true;
          if (this.activemenu == '') {
            this.activemenu = 'recommendation';
          }
        }
        if (menuarray.includes('genrativeai')) {
          this.showgenerativemenu = true;
          if (this.activemenu == '') {
            this.activemenu = 'genrativeai';
          }

        }
        if (menuarray.includes('timeseries')) {
          this.showtimeseriesmenu = true;
          if (this.activemenu == '') {
            this.activemenu = 'timeseries';
          }
        }
        if (menuarray.includes('anomalydetection')) {
          this.showanomalydetection = true;
          if (this.activemenu == '') {
            this.activemenu = 'anomalydetection';
          }
        }
        this.getrepodata();
      },
      (err) => {
        console.log(err)
      }
    );
  }

  getDynamicCardCreation() {
    this.auth.dynamicCardCreation()
      .subscribe(
        (response) => {
          // console.log("response:",response);
          response.forEach((ele: any) => {
            if (ele.menu_name == 'computerdata') {
              this.computerdata = response.filter(ele => ele.menu_name == 'computerdata' && ele.id < 16);
            }
            if (ele.menu_name == 'computerdata') {
              this.coviddata = response.filter(ele => ele.menu_name == 'computerdata' && ele.id >= 16);
            }
            if (ele.menu_name == 'nlpdata') {
              this.nlpdata = response.filter(ele => ele.menu_name == 'nlpdata');
            }
            if (ele.menu_name == 'sounddata') {
              this.sounddata = response.filter(ele => ele.menu_name == 'sounddata');
            }
            if (ele.menu_name == 'automl') {
              this.automl = response.filter(ele => ele.menu_name == 'automl');
            }
            if (ele.menu_name == 'telescope') {
              this.telescope = response.filter(ele => ele.menu_name == 'telescope');
            }
            if (ele.menu_name == 'recommendation') {
              this.recommendation = response.filter(ele => ele.menu_name == 'recommendation');
            }
            if (ele.menu_name == 'genrativeai') {
              this.genrativeai = response.filter(ele => ele.menu_name == 'genrativeai');
            }
            if (ele.menu_name == 'timeseries') {
              this.timeseries = response.filter(ele => ele.menu_name == 'timeseries');
            }
            if (ele.menu_name == 'anomalydetection') {
              this.anomalydetection = response.filter(ele => ele.menu_name == 'anomalydetection');
            }
          });
          this.getrepodata();
        },
        (err) => {
          console.log(err)
        }
      );
  }


  onAutomationTabClick() {
    this.isLoading = true;
    this.auth.getAutomationReportData().subscribe(
      (response) => {
        // console.log('@@@@onAutomationTabClick@@@', response);
        this.detailReport = response;
        this.detailReport.reverse()
        this.updateTableDataSource(); // Update the table data source
        this.updateTableDataSourceRestartTable();
        this.isLoading = false;
      }
    );
  }

  onReportGenerate() {
    this.isLoadingGenerateReport = true;
    // Set a timeout to remove the loader after 5 seconds
    setTimeout(() => {
      this.isLoadingGenerateReport = false;
    }, 5000);
    this.auth.automationSerivceApi().subscribe(
      (response) => {
        // console.log('@@@@onAutomationTabClick@@@', response);
        this.detailReport = response;
        this.detailReport.reverse()
        this.updateTableDataSource(); // Update the table data source
      }
    );
  }

  applyFilter2(event: Event) {
    console.log(event)
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource)
  }

//   applyFilter(column: string, event: Event) {
//   const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
//   this.dataSource2.filterPredicate = (data, filter) => {
//     const value = data[column] ? data[column].toString().toLowerCase() : '';
//     return value.includes(filter);
//   };
//   this.dataSource2.filter = filterValue;
// }


applyFilterApplicationRestart(event: Event) {
  if (!event || !event.target) {
    console.error('Event or event.target is undefined');
    return; // Exit the method if there's no valid event
  }
  const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
  this.dataSource2.filter = filterValue.trim().toLowerCase();
}

  

  updateTableDataSource() {
    this.dataSource = new MatTableDataSource(this.detailReport);
    this.totalLength = this.detailReport.length;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  updateTableDataSourceRestartTable() {
    this.dataSource2.data = []; // Clear data first
    // Assign `restartappdata` to `dataSource2.data`
    this.dataSource2.data = this.restartappdata;
    this.originalapprestartdata = this.restartappdata;
    console.log(this.dataSource2, 'Updated dataSource2');
    
    // Update the total length for pagination
    this.totalLength2 = this.restartappdata.length;
  
    
  }
  
  

  getDownloadUrl(filePath: string): string {
    return `${environment.domainURL}/download/${filePath}`;
  }

  showReport(batchName: string) {
    // Fetch data based on batchName
    this.isLoading = true;
    this.auth.getBatchData(batchName).subscribe(
      (data: any) => {
        this.selectedBatchData = data;
        this.isReportPopupVisible = true; // Show popup
        this.isLoading = false;
      },
      (error: any) => {
        console.error('Error fetching application status data:', error);
      }
    );
  }

  closePopup() {
    this.isReportPopupVisible = false;
  }

  restartFrontendApplication(application_name: string): void {
    this.isLoading = true;
    this.auth.restartFrontendApps(application_name).subscribe(
      (response) => {
        console.log('Restart successful:', response);
        this.isLoading = false;
        alert(`Action '${response.status}' executed successfully!`);
      },
      (error) => {
        console.error('Error during restart:', error);
        alert(`Error: ${error}`);
      }
    );
  }
  

  restartBackendApplication(application_name: string): void {
    this.isLoading = true;
    this.auth.restartBackendApps(application_name).subscribe(
      (response) => {
        console.log('Restart successful:', response);
        this.isLoading = false;
        alert(`Action '${response.status}' executed successfully!`);
      },
      (error) => {
        console.error('Error during restart:', error);
        alert(`Error: ${error}`);
      }
    );
  }

  getAllUsersDetails() {
    this.auth.getAllUsersDetails()
      .subscribe(
        (response: UserData[]) => {
          this.dataSourcetable.data = response;
        },
        (err) => {
          console.log(err);  // Handle any errors
        }
      );
  }

  getAllMenuNames() {
    this.auth.getAllMenuNames()
      .subscribe(
        (response: any) => {
          this.menuNames = response;
          console.log(this.menuNames)

        },
        (err) => {
          console.log(err);  // Handle any errors
        }
      );
  }

  


  getAllApplicationDetails() {
    this.auth.getAllApplicationDetails()
      .subscribe(
        (response: ApplicationData[]) => {
          this.dataSourceapplicationstable.data = response;
          console.log(this.dataSourceapplicationstable.data,'------------------')
          this.originalData = response; // Keep a backup of the original data
        },
        (err) => {
          console.log(err);  // Handle any errors
        }
      );
  }
  openModal() {
    $('#formModal').modal('show');
    this.applicationDetaisl = {
      id: 0,
      menuName: "",
      appName: "",
      appUrl: "",
      appIndustry: "",
      appFunction: "",
      appStatus: 1,
      appDescription: "",
      appDocs: "",
      backendApiUrl: "",
      loginMethod: "",
      applicationUsername: "",
      applicationPassword: "",
      buttontext: "",
      buttonstate: 0,
      color: "",
      appImage: "",
      userAccess: []
    }
    this.selectedFile = null;
    this.selectedImageFile = null;
    this.showUploadfileButton = false;
    this.showUploadimageButton = false;
  }

  // Function to close the modal
  closeModal() {
    this.isEditing = false;
    $('#formModal').modal('hide');

  }

 

  editApplicationModal(modaldata: any) {
    this.isEditing = true;
    $('#formModal').modal('show');
    this.applicationDetaisl.id = modaldata.id
    this.applicationDetaisl.menuName = modaldata.menu_name
    this.applicationDetaisl.appName = modaldata.application_name
    this.applicationDetaisl.appUrl = modaldata.application_url
    this.applicationDetaisl.appIndustry = modaldata.industry
    this.applicationDetaisl.appStatus = modaldata.application_status
    this.applicationDetaisl.appFunction = modaldata.function
    this.applicationDetaisl.appDocs = modaldata.pdf ? modaldata.pdf : modaldata.ppt
    this.applicationDetaisl.appDescription = modaldata.descriptiontext
    this.applicationDetaisl.appImage = modaldata.imagepath
    this.applicationDetaisl.backendApiUrl = modaldata.feature_url
    this.applicationDetaisl.loginMethod = modaldata.login_method
    this.applicationDetaisl.applicationUsername = modaldata.userName
    this.applicationDetaisl.applicationPassword = modaldata.password
    this.applicationDetaisl.userAccess = modaldata.userAccess

  }

  submitApplication(data: any) {
    this.applicationDetaisl.buttontext = "Demo";
    this.applicationDetaisl.buttonstate = 0;
    this.applicationDetaisl.color = null;
    console.log(this.applicationDetaisl,'-----------------------')

    if (this.applicationDetaisl.appName != '' && this.applicationDetaisl.menuName != '' && this.applicationDetaisl.appUrl != '' && this.applicationDetaisl.appDescription != '' && this.applicationDetaisl.appIndustry != '' && this.applicationDetaisl.appFunction != '' && this.applicationDetaisl.appDocs != '' && this.applicationDetaisl.appImage != '' && this.applicationDetaisl.backendApiUrl != '' && this.applicationDetaisl.loginMethod != '' && this.applicationDetaisl.applicationUsername != '' && this.applicationDetaisl.applicationPassword != '' && this.applicationDetaisl.userAccess) {

      if (this.isEditing == false) {
        this.isLoading = true;
        this.auth.addApplicationDetails(this.applicationDetaisl).subscribe(
          response => {
            alert(response.message);
            this.isLoading = false
            this.closeModal();
            this.getAllApplicationDetails();
          },
          error => {
            this.isLoading = false
            console.error('Error submitting application details:', error);
            alert('Failed to submit the form. Please try again.');

          }
        );
      }
      else {
        this.isEditing = true;
        this.isLoading = true;
        this.editSelectedUser 
        this.editDeselectedUser 
        let data = {"applicationDetaisl":this.applicationDetaisl, "selectedUser":this.editSelectedUser, "deSelecteduser": this.editDeselectedUser}
        this.auth.UpdateApplicationDetails(data).subscribe(
          response => {
            alert(response.message);
            this.isLoading = false
            this.closeModal();
            this.getAllApplicationDetails();
          },
          error => {
            console.error('Error submitting application details:', error);
            alert('Failed to submit the form. Please try again.');
          }
        );
      }
    } else {
      alert('Please fill all the fields')
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    const allowedFileTypes = ['application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];

    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        this.isFileTypeInvalid = true;
        event.target.value = ''; // Clear the file input
      } else {
        this.isFileTypeInvalid = false;
        this.selectedFile = file;
        this.showUploadfileButton = true;
      }
    }
    // this.auth.uploadFile(this.selectedFile).subscribe(
    //   response => {
    //     console.log(response);
    //     this.applicationDetaisl.appDocs = response.fileUrl
    //   },
    //   error => {
    //     console.error('Error:', error);
    //     // alert('Failed to submit the form. Please try again.');
    //   }
    // );

  }

  uploadFiles(uploadFiles): void {
    if (this.selectedFile) {
      this.isLoading = true
      setTimeout(() => {
        this.auth.uploadFile(this.selectedFile).subscribe(
          response => {
            this.applicationDetaisl.appDocs = response.fileUrl;
            this.isLoading = false
          },
          error => {
            console.error('Error:', error);
            // alert('Failed to submit the form. Please try again.');
          }
        );
      }, 2000)

    }
    else {
      alert("pleae upload a  file")
    }

  }

  onImageFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      const validImageFileTypes = ['image/jpeg', 'image/jpg'];

      if (validImageFileTypes.includes(fileType)) {
        this.selectedImageFile = file;
        this.applicationDetaisl.appImage = file.name;  // Store the image filename in appImage
        this.showUploadimageButton = true;
        this.isImageFileTypeInvalid = false;
      } else {
        this.selectedImageFile = null;
        this.applicationDetaisl.appImage = "";  // Clear appImage if invalid file
        this.isImageFileTypeInvalid = true;

      }

      // this.auth.uploadFile(this.selectedImageFile).subscribe(
      //   response => {
      //     console.log(response);
      //     this.applicationDetaisl.appImage = response.fileUrl;
      //   },
      //   error => {
      //     console.error('Error:', error);
      //     // alert('Failed to submit the form. Please try again.');
      //   }
      // );
    }
  }

  uploadImage(event: any): void {
    if (this.selectedImageFile) {
      this.isLoading = true
      setTimeout(() => {
        this.auth.uploadFile(this.selectedImageFile).subscribe(
          response => {
            this.applicationDetaisl.appImage = response.fileUrl;
            this.isLoading = false
          },
          error => {
            this.isLoading = false
            console.error('Error:', error);
            // alert('Failed to submit the form. Please try again.');
          }
        );
      }, 2000)

    } else {
      alert("pleae upload a jpg file")
    }

  }

  openUserModal() {
    this.appaccessStatus=[];
    this.applicationDetails = [];
    this.userDetails = {
      id: 0,
      userName: "",
      userEmail: "",
      userJobTitle: "",
      userCompanyName:"",
      userPassword:"",
      userCreditAssigned: null,
      userCreditBalance: "",
      menuName:"",
      appaccessNames:[],
      appaccessdata:[]
    };
    $('#userFormModal').modal('show');
  }

  closeUserModal() {
    this.isEditingUserModal=false;
    this.appaccessStatus=[]
    this.userDetails = {
      id: 0,
      userName: "",
      userEmail: "",
      userJobTitle: "",
      userCompanyName:"",
      userPassword:"",
      userCreditAssigned: null,
      userCreditBalance: "",
      menuName:"",
      appaccessNames:[],
      appaccessdata:[]
    };
    $('#userFormModal').modal('hide');

  }

  applyApplicationFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceapplicationstable.filter = filterValue.trim().toLowerCase();
  }

  applyuserFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourcetable.filter = filterValue.trim().toLowerCase();
  }
 
  applyApplicationMenu(event: KeyboardEvent) {
    const searchKey = (event.target as HTMLInputElement).value.toLowerCase().trim();

    // If the search input is empty, reset to the original list
    if (searchKey === '') {
      this.applicationDetails = [...this.originalApplications];
    } else {
      // Filter applications based on the search term
      this.applicationDetails = this.originalApplications.filter(app =>
        app.application_name.toLowerCase().includes(searchKey)
      );
    }

    // console.log(this.applicationDetails, 'Filtered/Reset Applications');
  }


  onMenuChange(selectedMenu: string): void {
    this.userDetails.appaccessNames = [];
    this.isLoading = true
    this.auth.getAllApplicationNames(selectedMenu).subscribe(response => {
  
      
      this.applicationDetails = response;
      this.originalApplications = [...this.applicationDetails];
      // console.log(this.applicationDetails)
      this.isLoading = false;
      // Handle the response
    }, error => {
      console.error('API error:', error);
    });
  }

// Check if the application is checked
isAppChecked(applicationId: number): boolean {
  const appEntry = this.appaccessStatus.find(entry => entry.applicationId === applicationId);
  const isChecked = appEntry ? appEntry.appaccessStatus : false; // Return false if not found
  // console.log(`Application ID: ${applicationId}, Checked: ${isChecked}`); // Log for debugging
  return isChecked;
}

// Toggle application access status
toggleOption(applicationId: number, isChecked: boolean): void {
  const appEntry = this.appaccessStatus.find(entry => entry.applicationId === applicationId);

  if (appEntry) {
    // If entry exists, update the appaccessStatus
    appEntry.appaccessStatus = isChecked; // Update to the new checked state
  } else {
    // If entry does not exist, add it with the current checked status
    this.appaccessStatus.push({ applicationId, appaccessStatus: isChecked });
  }
 

  // console.log('Current application statuses:', this.appaccessStatus); // Log current status
  this.userDetails.appaccessdata = this.appaccessStatus; // Save the updated status
}


editUserModal(modaldata: any) {
  this.isEditingUserModal = true;
  this.appaccessStatus = [];
  this.applicationDetails = [];
  this.userDetails.userName = modaldata.name
  this.userDetails.userEmail = modaldata.email
  this.userDetails.userCompanyName = modaldata.companyname
  this.userDetails.userPassword = modaldata.password
  this.userDetails.userJobTitle = modaldata.jobtitle
  this.userDetails.userCreditBalance = modaldata.credits_balance
  this.userDetails.id = modaldata.id
  // this.onMenuChangeAPIedit(modaldata,selectedMenu)
  $('#userFormModal').modal('show');
 
}

onMenuChangeAPIedit(selectedMenu: string){
  
  this.auth.getUserAccessAppDetails(selectedMenu,this.userDetails.id).subscribe(response => {
   this.applicationDetails = response;
   this.originalApplications = [...this.applicationDetails];
   this.appaccessStatus = response.map((app:any) => ({
    applicationId: app.application_id, // Ensure you're using application_id
    appaccessStatus: app.access_status === '1' // Convert access_status to boolean
  }));
    
  }, error => {
    console.error('API error:', error);
    // Handle error appropriately, maybe show a message to the user
  });

}


  submitUserDetails(userDet:any){
    if (this.userDetails.userName != '' && this.userDetails.userEmail != '' && this.userDetails.userPassword != '' && this.userDetails.userCompanyName != '' && this.userDetails.userJobTitle != '') {
      if (this.isEditingUserModal == false) {
        this.isLoading = true;
        this.auth.addUserDetails(this.userDetails).subscribe(
          response => {
            alert(response.message);
            this.isLoading = false
            this.closeUserModal();
            this.getAllUsersDetails();
          },
          error => {
            console.error('Error submitting application details:', error);
            alert('Failed to submit the form. Please try again.');
          }
        );
      }

      else {
        this.isEditingUserModal = true;
        this.isLoading = true;
        this.auth.updateUserDetails(this.userDetails).subscribe(
          response => {
            alert(response.message);
            this.isLoading = false
            this.closeUserModal();
            this.getAllUsersDetails();
          },
          error => {
            console.error('Error submitting application details:', error);
            alert('Failed to submit the form. Please try again.');
          }
        );
      }
    }
    else {
      alert('Please fill the required fields')
    }
  }
  editSelectedUser = []
  editDeselectedUser = []
  onSelectingUser(event: any) {
    if (this.isEditing === true) {
      this.editSelectedUser.push(event)
    }
    // console.log("onclicking edit ",this.applicationDetaisl.userAccess);
  }

  onDeselectUser(event: any) {
    // console.log("Deselected User: ", event);
    if (this.isEditing === true) {
      this.editDeselectedUser.push(event)
      // console.log('this.editSelectedUser', this.editDeselectedUser);
    }
    // console.log(this.applicationDetaisl.userAccess)
  }

  onSelectAllUsers(event: any) {
    console.log("All Users Selected: ", event);
  }

  onDeselectAllUsers() {
    console.log("All Users Deselected");
  }

  deleteUser(deleteUserData: any) {
    // Show a confirmation dialog
    const confirmed = confirm("Are you sure you want to delete this user? This action cannot be undone.");
  
    if (confirmed) {
      this.isLoading = true;
      this.auth.deleteUserDetails(deleteUserData).subscribe(
        (response) => {
          alert(response.message);
          this.isLoading = false;
          this.getAllUsersDetails();
        },
        (error) => {
          this.isLoading = false; // Stop loading if there's an error
          console.error("Error deleting user:", error);
          alert("An error occurred while deleting the user. Please try again.");
        }
      );
    } else {
      // If the user cancels, just return without doing anything
      console.log("User deletion canceled.");
    }
  }
  filterApplications() {
    if (this.searchText) {
      const searchLower = this.searchText.toLowerCase();
      this.filteredApplications = this.applicationDetails.filter(app =>
        app.application_name.toLowerCase().includes(searchLower)
      );
    } else {
      // If no search text, show all applications
      this.filteredApplications = this.applicationDetails;
    }
  }

  onSelectingMenuName(event: any) {
    console.log("Selected menu names:", this.dataFiletermenuname);

    // Reset to the original data source to start fresh
    this.dataSourceapplicationstable.data = [...this.originalData];

    if (this.dataFiletermenuname.length > 0) {
        // Filter data based on selected menu names
        this.dataSourceapplicationstable.data = this.dataSourceapplicationstable.data.filter(app =>
            this.dataFiletermenuname.some(selected => selected.menu_name === app.menu_name)
        );

        // Create a unique application name list based on the filtered data
        this.filteredApplicationNames = Array.from(
            new Set(this.dataSourceapplicationstable.data.map(app => app.application_name))
        );

        console.log("Filtered application names:", this.filteredApplicationNames);
    } else {
        // Reset to show all data if no menu name is selected
        this.dataSourceapplicationstable.data = this.originalData;
        this.filteredApplicationNames = [...this.originalApplicationNames]; // Show all applications
    }
}

onSelectingApplicationNames() {
  console.log("Selected application names:", this.dataFileterApplicationname);
  console.log("Current data source before filtering:", this.dataSourceapplicationstable.data);

  // If there are selected application names, filter the data based on them
  if (this.dataFileterApplicationname.length > 0) {
      this.dataSourceapplicationstable.data = this.originalData.filter(app =>
          this.dataFileterApplicationname.includes(app.application_name)
      );
  } 
  // If no application names are selected but there are menu names selected,
  // we should keep the filtered data by menu names.
  else if (this.dataFiletermenuname.length > 0) {
      // Keep filtering by menu names only
      this.dataSourceapplicationstable.data = this.originalData.filter(app =>
          this.dataFiletermenuname.some(selected => selected.menu_name === app.menu_name)
      );
  } 
  // If no menu names or application names are selected, reset to original data
  else {
      this.dataSourceapplicationstable.data = [...this.originalData];
  }

  console.log("Filtered data source after application selection:", this.dataSourceapplicationstable.data);
}


onDeselectMenuName(event: any) {
    this.dataFiletermenuname = this.dataFiletermenuname.filter(
        selected => selected.menu_name !== event.menu_name
    );
    this.onSelectingMenuName(event); // Refresh the data based on remaining selected menu names
}

onDeselectApplicationNames(event: any) {
  // Remove the deselected application name
  this.dataFileterApplicationname = this.dataFileterApplicationname.filter(
      appName => appName !== event.application_name
  );

  // Call the selection method again to refresh filtering
  this.onSelectingApplicationNames();
}

onSelectingMenuNamereStart(event: any) {
  console.log("Selected menu names:", this.dataFiletermenuname);

  // Reset to the original data source to start fresh
  this.dataSource2.data = [...this.originalapprestartdata];

  if (this.dataFiletermenuname.length > 0) {
      // Filter data based on selected menu names
      this.dataSource2.data = this.dataSource2.data.filter(app =>
          this.dataFiletermenuname.some((selected:any) => selected.menu_name === app.menu_name)
      );

      // Create a unique application name list based on the filtered data
      this.filteredApplicationNames = Array.from(
          new Set(this.dataSource2.data.map(app => app.application_name))
      );

      console.log("Filtered application names:", this.filteredApplicationNames);
      console.log(this.dataSource2.data)
  } else {
      // Reset to show all data if no menu name is selected
      this.dataSource2.data = this.originalapprestartdata;
      this.filteredApplicationNames = [...this.originalApplicationNames]; // Show all applications
  }
}

onDeselectMenuNamereStart(event: any) {
  this.dataFiletermenuname = this.dataFiletermenuname.filter(
      selected => selected.menu_name !== event.menu_name
  );
  this.onSelectingMenuNamereStart(event); // Refresh the data based on remaining selected menu names
}

onSelectingApplicationNamesreStart() {
  console.log("Selected application names:", this.dataFileterApplicationname);
  console.log("Current data source before filtering:", this.dataSource2.data);

  // If there are selected application names, filter the data based on them
  if (this.dataFileterApplicationname.length > 0) {
      this.dataSource2.data = this.originalapprestartdata.filter(app =>
          this.dataFileterApplicationname.includes(app.application_name)
      );
  } 
  // If no application names are selected but there are menu names selected,
  // we should keep the filtered data by menu names.
  else if (this.dataFiletermenuname.length > 0) {
      // Keep filtering by menu names only
      this.dataSource2.data = this.originalapprestartdata.filter(app =>
          this.dataFiletermenuname.some(selected => selected.menu_name === app.menu_name)
      );
  } 
  // If no menu names or application names are selected, reset to original data
  else {
      this.dataSource2.data = [...this.originalapprestartdata];
  }

  console.log("Filtered data source after application selection:", this.dataSource2.data);
}

onDeselectApplicationNamesreStart(event: any) {
  // Remove the deselected application name
  this.dataFileterApplicationname = this.dataFileterApplicationname.filter(
      appName => appName !== event.application_name
  );

  // Call the selection method again to refresh filtering
  this.onSelectingApplicationNamesreStart();
}


}
