import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(public auth: AuthenticationService, public router: Router) {}

  visible: boolean;
  headerstate: string;

  ngOnInit() {
    // this.headerstate = sessionStorage.getItem('header');
    // console.log(this.headerstate);
    // const isFalseSet = (this.headerstate === 'true');
    this.visible = true;
  }

  headerenable() {
    return this.router.url.indexOf('login') !== -1 || this.router.url.indexOf('register') !== -1;
  }

}
