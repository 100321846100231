import { Component, OnInit } from '@angular/core';
import { AuthenticationService, TokenPayload } from '../authentication.service';
import { Router } from '@angular/router';
import { RegisterserviceService } from '../registerservice.service';
import * as $ from 'jquery';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

@Component({
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {


   errregmsg: boolean;
   mailsent: boolean;
   header: string;

  credentials: TokenPayload = {
    id: 0,
    name: '',
    companyname: '',
    email: '',
    jobtitle: '',
    password: this.generatePassword(),
    created: this.generateDate()
  };

  emailid: string;

  // tslint:disable-next-line:max-line-length
  constructor( private auth: AuthenticationService, private router: Router, private registerserviceService: RegisterserviceService) {}


  ngOnInit() {

    // sessionStorage.removeItem('header');
    // this.header = 'true';
    // sessionStorage.setItem('header', this.header);

    this.errregmsg = false;

    sessionStorage.setItem('password', this.credentials.password);


    const targetElement = $('#offscroll');

    disableBodyScroll(targetElement);

  //   $(document).ready(function() {
  //     $('button').click(function() {
  //         $('#textmsg').text('Sending E-mail...Please wait');

  //         setTimeout(function() {
  //            $('#textmsg').empty().html('<p>Email has been sent. Please check the inbox !</p>'); }, 2000);

  //     });
  // });

  }




  register(model) {

    sessionStorage.setItem('emailid', model.email);

    this.auth.register(this.credentials).subscribe(
      () => {
        // this.router.navigateByUrl('/profile');
        console.log('registered');
        this.mailsent = true;

      },
      err => {
        // console.log(err);
        this.errregmsg = true;
      }
    );




    this.registerserviceService.getSummaryData().subscribe((data) => {

      console.log('mail sent register');

    });

  }


  generatePassword() {
    let length = 8,
        charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
        retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  generateDate() {
    const today = new Date();
    return today;
  }


}
