import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegisterserviceService {

  emailid: string;
  rand: string;
  url:any;

  constructor(private httpClient: HttpClient) {
    this.url = 'http://localhost:5032/';
    // this.url = 'https://neuron.affineanalytics.ai/';
   }

  public getSummaryData() {

    this.emailid = sessionStorage.getItem('emailid');

    this.rand = sessionStorage.getItem('password');

    console.log(this.emailid);

    // let params = new HttpParams();

    // params = params.set('user', this.emailid);

    const params = new HttpParams()
    .set('username', this.emailid)
    .set('password', this.rand);

    return this.httpClient.get('http://localhost:5032/send',  {params: params});


  }



}
