import { Component, OnInit } from "@angular/core";
import { AuthenticationService, TokenPayload } from "../authentication.service";
import { Router } from "@angular/router";
import * as $ from "jquery";

// 1. Import the functions
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

@Component({
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  errmsg: boolean;
  userdata: any;
  header: string;
  accesslevel: boolean;
  accesslevel2: string;
  credentials: TokenPayload = {
    id: 0,
    name: "",
    email: "",
    companyname: "",
    password: "",
    jobtitle: "",
    created: this.generateDate(),
  };

  constructor(private auth: AuthenticationService, private router: Router) {}

  ngOnInit() {
    //   sessionStorage.removeItem('header');
    //  this.header = 'true';
    //   sessionStorage.setItem('header', this.header);
    this.errmsg = false;

    sessionStorage.removeItem("isLoggedin");

    const targetElement = $("#offscroll");

    disableBodyScroll(targetElement);
  }

  login() {
    sessionStorage.setItem("isLoggedin", "true");
    this.auth.login(this.credentials).subscribe(
      (response) => {
        localStorage.setItem("token", response.token);
        localStorage.setItem("expiretime", response.expiretime);
        localStorage.setItem("loginResponse", JSON.stringify(response));
        localStorage.setItem("email", response.user);
        this.errmsg = false;
        this.userdata = this.credentials;

        sessionStorage.setItem("accesslevel", "true");
        if(response.appAccess != ''){
          this.router.navigateByUrl("/dashboard");
        }
        else{
          alert("Permission Denied");
        }

        // if (response.application_url) {
        //   const applicationUrls = response.application_url.split(",");
        //   const projectNames = response.project_name.split(",");

        //   // now if user have one app we will redirect them to dashboard

         

        //   // if (response.appAccess.length === 1) {
        //   //   window.location.href =
        //   //     applicationUrls[0] + "?token=" + response.token;
        //   // } else {
        //   //   console.log("Response project name is:", response.projectname);
        //   //   this.router.navigateByUrl("/dashboard");
        //   // }
        // } else {
        //   console.log("Application URL not found in the response");
        //   this.router.navigateByUrl("/dashboard");
        // }
      },
      (err) => {
        this.errmsg = true;
      }
    );
  }

  generateDate() {
    const today = new Date();
    return today;
  }
}
