import { Injectable } from "@angular/core";
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment.prod";
import { catchError } from 'rxjs/operators';

export interface UserDetails {
  id: number;
  name: string;
  companyname: string;
  email: string;
  jobtitle: string;
  password: string;
  created: Date;
  exp: number;
  iat: number;
}

interface TokenResponse {
  token: string;
}

export interface TokenPayload {
  id: number;
  name: string;
  companyname: string;
  email: string;
  jobtitle: string;
  password: string;
  created: Date;
}

export interface AppDetails {
  id:number,
  menuName: string;
  appName: string;
  appUrl: string;
  appIndustry: string;
  appFunction: string;
  appStatus:number;
  appDescription: string;
  appDocs:string;
  backendApiUrl: string;
  loginMethod: string;
  applicationUsername: string;
  applicationPassword: string;
  buttontext: string;
  buttonstate: number;
  color: string;
  appImage: string, 
  userAccess:any[]
}

export interface UserAppDetails {
  id:number,
  userName: string;
  userEmail: string;
  userJobTitle: string;
  userCreditAssigned: null;
  userCompanyName:string;
  userPassword:string;
  userCreditBalance: string;
  menuName:string;
  appaccessNames:[];
  appaccessdata: { applicationId: number; appaccessStatus: boolean }[];
}

export interface FileDetails{
  file: File
}

export interface DeleteUserDetails{
  user_id : number;
}


@Injectable()
export class AuthenticationService {
  private token: string;

  constructor(private http: HttpClient, private router: Router) {}

  private saveToken(token: string): void {
    localStorage.setItem("usertoken", token);
    this.token = token;
  }

  private getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem("usertoken");
    }
    return this.token;
  }

  public getUserDetails(): UserDetails {
    const token = this.getToken();
    let payload;
    if (token) {
      payload = token.split(".")[1];
      payload = window.atob(payload);
      return JSON.parse(payload);
    } else {
      return null;
    }
  }

  public isLoggedIn(): boolean {
    const user = this.getUserDetails();
    if (user) {
      return user.exp > Date.now() / 1000;
    } else {
      return false;
    }
  }

  public register(user: TokenPayload): Observable<any> {
    return this.http.post(`/users/register`, user);
  }

  public login(user: TokenPayload): Observable<any> {
    const base = this.http.post(`/users/login`, user);

    const request = base.pipe(
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
    );

    return request;
  }

  public userdata(email: any): Observable<any> {
    // Construct the URL with query parameters
    const url = `/users/userdata?email=${email}`;

    // Use HTTP GET request instead of POST
    const base = this.http.get(url);

    const request = base.pipe(
      map((data: TokenResponse) => {
        // if (data.token) {
        //   this.saveToken(data.token);
        // }
        return data;
      })
    );

    return request;
  }

  public getAllApplicationNames(menuName: any): Observable<any> {
    // Construct the URL with query parameters
    const url = `/users/getAppNames?menuName=${menuName}`;
    
    //Use HTTP GET request instead of POST
    const base = this.http.get(url);

    const request = base.pipe(
      map((data) => {
        // if (data.token) {
        //   this.saveToken(data.token);
        // }
        return data;
      })
    );

    return request;
  }

  public dynamicCardCreation(): Observable<any> {
    // Construct the URL with query parameters
    const url = `/users/dynamicCardCreation`;

    // Use HTTP GET request instead of POST
    const base = this.http.get(url);

    const request = base.pipe(
      map((data) => {
        return data;
      })
    );

    return request;
  }

  public automationSerivceApi(): Observable<any> {
    const url = `/users/getAllDetails`;
    return this.http.get(url).pipe(
      tap((data: any) => {
        // console.log('Data received from automationSerivceApi:', data);
      }),
      switchMap((data: any) => this.automationDetailsApi(data)),
      switchMap(() => this.getAutomationReportData()) // Call getAutomationReportData() after automationDetailsApi()
    );
  }
 
  private automationDetailsApi(data: any): Observable<any> {
    const automationDetailsApiUrl = environment.domainURL+'/db_details/';
    return this.http.post(automationDetailsApiUrl, data);
  }
 
  public getAutomationReportData(): Observable<any> {
    const url = environment.domainURL+'/fetch_records/';
    
    return this.http.get(url).pipe(
      tap((data: any) => {
        console.log('Data received from getAutomationReportData:', data);
      }),
    );
  }

  public getBatchData(batchName: any): Observable<any> {
    const automationDetailsApiUrl = environment.domainURL+`/get_application_batch_data/${batchName}`;
    return this.http.get(automationDetailsApiUrl);
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError(error.message || 'Server Error');
  }

  restartFrontendApps(application_name: string, action: string = 'restart'): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = { application_name, action };

    return this.http.post(environment.domainURL+`/restart_nginx_frontend/`, body, { headers })
    .pipe(
      catchError(this.handleError)
    );

  }


  restartBackendApps(application_name: string, action: string = 'restart'): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = { application_name, action };

    return this.http.post(environment.domainURL+`/restart_backend_applications/`, body, { headers })
    .pipe(
      catchError(this.handleError)
    );

  }

  public showAllRestartApplication(): Observable<any> {
    const automationDetailsApiUrl = environment.domainURL+`/applications_data`;
    return this.http.get(automationDetailsApiUrl);
  }

  public pm2StatusCheck(): Observable<any> {
    const pm2statuscheck = environment.domainURL+`/pm2_status_check`;
    return this.http.get(pm2statuscheck);
  }


  public logout(): void {
    this.token = "";
    window.localStorage.removeItem("usertoken");
    this.router.navigateByUrl("/");
  }

  public getAllUsersDetails(): Observable<any> {
    // Construct the URL with query parameters
    const url = `/users/geAllusersDetails`;

    // Use HTTP GET request instead of POST
    const base = this.http.get(url);

    const request = base.pipe(
      map((data: TokenResponse) => {
        // if (data.token) {
        //   this.saveToken(data.token);
        // }
        return data;
      })
    );

    return request;
  }


  public getAllApplicationDetails(): Observable<any> {
    // Construct the URL with query parameters
    const url = `/users/geAllApplicationsDetails`;

    // Use HTTP GET request instead of POST
    const base = this.http.get(url);

    const request = base.pipe(
      map((data: TokenResponse) => {
        // if (data.token) {
        //   this.saveToken(data.token);
        // }
        return data;
      })
    );

    return request;
  }

  public getAllMenuNames(): Observable<any> {
    // Construct the URL with query parameters
    const url = `/users/geAllMenuNames`;

    // Use HTTP GET request instead of POST
    const base = this.http.get(url);

    const request = base.pipe(
      map((data: TokenResponse) => {
        // if (data.token) {
        //   this.saveToken(data.token);
        // }
        return data;
      })
    );

    return request;
  }

 



  public getUserAccessAppDetails(menuName:any,user_id:any): Observable<any> {
    const url = `/users/userAppaccessDetails?menuName=${menuName}&user_id=${user_id}`;

    const base = this.http.get(url);

    const request = base.pipe(
      map((data: TokenResponse) => {
        return data;
      })
    );
    return request;
  }
  

  public addApplicationDetails(data:AppDetails): Observable<any> {
    console.log(data,'-------------------------------servide file--------------------------------------')
    const base = this.http.post(`/users/add-application`, data);

    const request = base.pipe(
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
    );

    return request;
  }
 
  
  
  public UpdateApplicationDetails(data): Observable<any> {
    console.log(data,'-------------------------------servide file--------------------------------------')
    const base = this.http.put(`/users/update-application`, data);

    const request = base.pipe(
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
    );

    return request;
  }

   // File upload method
   uploadFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);  // 'file' is the name expected by the Node.js backend
    const apiUrl = `/users/upload`
 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
 
    return this.http.post(apiUrl, formData, { headers });
  }

  public addUserDetails(data:UserAppDetails): Observable<any> {
    const base = this.http.post(`/users/add-userDetails`, data);

    const request = base.pipe(
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
    );

    return request;
  }

 public updateUserDetails(data:UserAppDetails):  Observable<any> {
  const base = this.http.post(`/users/update-userDetails`, data);

  const request = base.pipe(
    map((data: TokenResponse) => {
      if (data.token) {
        this.saveToken(data.token);
      }
      return data;
    })
  );

  return request;
}
  
  public getUserApplicationAccessData(user_id: any): Observable<any> {
    // Construct the URL with query parameters
    const url = `/users/userAppaccessDetails?user_id=${user_id}`;
    
    //Use HTTP GET request instead of POST
    const base = this.http.get(url);

    const request = base.pipe(
      map((data) => {
        // if (data.token) {
        //   this.saveToken(data.token);
        // }
        return data;
      })
    );

    return request;
  }

  public deleteUserDetails(data:DeleteUserDetails):  Observable<any> {
    const base = this.http.post(`/users/deleteUserDetails`, data);
  
    const request = base.pipe(
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
    );
  
    return request;
  }



}