import { BrowserModule } from '@angular/platform-browser';

import {
  CommonModule
} from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { AppComponent } from './app.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { AuthenticationService } from './authentication.service';
import { AppRoutingModule } from './app-routing.module';
import { NavigationComponent } from './shared/header-navigation/navigation.component';


import { AuthGuard, Globals } from './shared';
import { HttpClientModule, HttpClient, HttpClientXsrfModule } from '@angular/common/http';


/* Angular Material */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

/* Angular Flex Layout */
import { FlexLayoutModule } from '@angular/flex-layout';
import { from } from 'rxjs';

import { MustMatchDirective } from './_helpers/must-match.directive';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// const routes: Routes = [
//   { path: '', component: HomeComponent },
//   { path: 'login', component: LoginComponent },
//   { path: 'register', component: RegisterComponent },
//   {
//     path: 'profile',
//     component: ProfileComponent }
// ];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    RegisterComponent,
    HomeComponent,
    ProfileComponent,
    NavigationComponent,
    MustMatchDirective

  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
     HttpClientModule,
     AppRoutingModule,
     NgMultiSelectDropDownModule.forRoot(),
     HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
  ],
  providers: [AuthenticationService,  { provide: LocationStrategy, useClass: HashLocationStrategy },
  AuthGuard,
  Globals],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})


export class AppModule {}
