import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './shared';
// import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from '../app/login/login.component';
import { RegisterComponent } from '../app/register/register.component';
import { ProfileComponent } from '../app/profile/profile.component';

 const Approutes: Routes = [
  {
    path: '',

    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      // {
      //   path: 'Dashboard',
      //   loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
      //   canActivate: [AuthGuard]
      // },

      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'dashboard', component: ProfileComponent, canActivate: [AuthGuard] }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(Approutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
